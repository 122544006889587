@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,400;1,300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .carousel{
        @apply flex justify-center space-x-8 w-full
    }

    .grid-container{
        @apply inline-grid gap-2 grid-cols-2 grid-rows-2

    }
}

html {
    scroll-behavior: smooth;
}

.container {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius : 55px;
}

.centered-axis-xy {
    position : absolute;
    left : 50%;
    top : 50%;
    transform : translate(-50%, -50%);
}

.form-container {
    background : #F2F2F2;
    box-shadow : inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.float {
    box-shadow : 0px 4px 4px rgba(0, 0, 0, 0.25);
}

